/**
 * Created by MBX on 7/12/2017.
 */

export const EXAMS_COLS = [
	{ header: 'PROCEDURE_CODE', attr: 'procedureCode', hidden: false },
	{ header: 'ACCESSION_NUMBER', attr: 'accessionNumber', hidden: false },
	{ header: 'SPSSTATUS', attr: 'spsStatus', hidden: false },
	{ header: 'REPORTING_STATUS', attr: 'reportingStatus', hidden: false },
	{ header: 'MODALITY', attr: 'modality', hidden: false },
	{ header: 'IMAGINGCENTER', attr: 'imagingCenterName', hidden: false },
	{
		header: 'PERFORMING_PHYSICIAN',
		attr: 'performingPhysician',
		hidden: false,
	},
	{ header: 'TECHNICIAN', attr: 'technician', hidden: false },
	{ header: 'PATIENTARRIVAL', attr: 'patientArrival', hidden: false },
	{ header: 'EXAM_START', attr: 'examinationStarted', hidden: false },
	{ header: 'EXAM_FINISH', attr: 'examinationFinished', hidden: false },
	{ header: 'TOTAL_AMOUNT', attr: 'totalAmount', hidden: false },
	{ header: 'PAID_AMOUNT', attr: 'paidAmount', hidden: false },
	{ header: 'DISCOUNT', attr: 'discount', hidden: false },
];

export const PAYMENT_VIEW_COLUMNS = [
	'patientName',
	'procedureCode',
	'paymentStatus',
	'totalAmount',
	'paidAmount',
	'leftAmount',
	'discount',
	'globalDiscount',
	'billed',
];
