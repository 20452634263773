import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkflowComponent } from './workflow.component';
import { UserResolver } from '../user.resolver';

const routes: Routes = [
	{
		path: '',
		component: WorkflowComponent,
		resolve: { user: UserResolver },
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'workflow' },
			{ path: 'workflow', component: WorkflowComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class WorkflowRoutingModule {}
